<template>
  <div>
    <div class="input form-floating">
      <input
        v-bind="$attrs"
        autocomplete="off"
        ref="input"
        :type="type"
        :placeholder="placeholderName"
        :aria-label="ariaLabelName"
        :id="idName"
        @input="debounceSearch"
        @click="
          () => {
            showoptions = !showoptions;
          }
        "
        :class="{
            inputActive: arrowCounter === -1,
            'form-control input-select': true,
            'invalid is-invalid': hasError,
        }"
        style="background-image: none"
        v-model="model[label]"
        @keydown="onKeydown"
      />
      <img
        class="fake-select"
        :class="showoptions ? 'active' : ''"
        src="../../assets/img/Vector.svg"
        alt=""
        @click="
          () => {
            showoptions = !showoptions;
          }
        "
      />
      <label :for="idName">{{ labelName }}</label>
      <small v-if="hasError" class="text-danger mt-2"
             style="font-size:12px; font-family: 'Montserrat', sans-serif;">
        {{ error }}
      </small>
    </div>
    <div v-if="showoptions" class="options">
      <p
        @click="createOption"
        class="pointer create-option"
        v-if="!isOptionValid && model[label] && !notCreateItem"
      >
        <strong class="fw-normal">Criar</strong>
        {{ model[label] }}
      </p>
      <p class="small-text" v-if="!labelDescription">
        <u>Selecione</u> ou crie um(a) novo(a) {{ fieldName }}
      </p>
      <p class="small-text" v-else>
        {{ labelDescription }}
      </p>
      <ul class="list">
        <li
          v-for="(option, index) in options"
          :ref="`option_${index}`"
          :key="option[id]"
          @click="selectedOption(option)"
          :class="{ pillActive: arrowCounter === index }"
        >
          <span
            class="pill"
            v-if="option.cor !== undefined"
            :style="{
              background: option['cor'] || 'rgb(233, 237, 239)'
            }"
          >
            {{ option[label] }}
          </span>
          <span class="pill" v-else>{{ option[label] }}</span>
        </li>
        <li v-if="!options.length && this.model[this.label]">
          <span slot="noResult">Nenhum resultado encontrado.</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import returnGenericColor from '@/mixins/returnGenericColor';
import { debounce } from '@/plugins/debounce';

export default {
  name: 'EpInputSelectOrCreate',
  mixins: [returnGenericColor],
  props: {
    options: { type: Array, required: true, default: () => [] },
    id: { type: String || Number, required: true },
    label: { type: String || Number, required: true },
    labelName: { type: String, required: true },
    fieldName: { type: String, required: true },
    idName: { type: String, required: true },
    ariaLabelName: { type: String, required: true },
    placeholderName: { type: String, required: true },
    type: { type: String, required: true },
    nameEmit: { type: String, required: true },
    labelDescription: { type: String, required: false },
    call: {
      search: (value) => {
      },
      createNew: (value) => {
      },
      open: () => {
      }
    },
    selectadOptions: null,
    notCreateItem: Boolean,
    aditionalFilds: [],
    hasError: Boolean,
    error: String
  },
  data() {
    return {
      debounceSearch: () => {},
      showoptions: false,
      firstShow: 0,
      arrowCounter: -1,
      isOptionValid: false
    };
  },
  watch: {
    showoptions: function() {
      if (!this.options.length) {
        this.call.open();
      }
      this.options.map((option) => {
        if (option[this.label] === this.model[this.label]) {
          this.isOptionValid = true;
        }
      });
    }
  },
  computed: {
    model: {
      get() {
        return this.selectadOptions;
      },
      set(value) {
        this.showoptions = false;
        this.isOptionValid = false;
        this.$emit('update:change', value);
      }
    }
  },
  mounted() {
    this.debounceSearch = debounce(this.verifyIfOptionIsValidAndEmitValue, 600);
  },
  methods: {
    onKeydown() {
      if (!this.showoptions) {
        this.showoptions = true;
      }
    },
    createOption() {
      this.isOptionValid = true;
      this.showoptions = false;

      this.model[this.id] = null;
      this.call.createNew(this.model);
    },
    selectedOption(option) {
      this.model = option;
    },
    verifyIfOptionIsValidAndEmitValue() {
      this.call.search(this.model[this.label]);
      this.isOptionValid = false;
      this.options.map((option) => {
        if (option[this.label] === this.model[this.label]) {
          this.isOptionValid = true;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.input {
  margin-bottom: 2.5px;
}
.options {
  position: relative;
  background: #fff;
  z-index: 9999999;
  width: 100%;
  border-radius: 0 0 4px 4px;
  border: 1px solid #dcdde4;
  border-top: none!important;
  padding: 1rem 0.5rem;

  .create-option {
    width: 100%;
    padding: 4px;
    position: relative;
    top: 0;
    border: 1px solid #dcdde4;
    background: #fafafa;
    border-radius: 4px;
    color: #373c3f;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;

    strong {
      margin-right: 5px;
      color: #7f8588;
    }
  }
}

.fake-select {
  cursor: pointer;
  padding: 1.5rem 1.25rem 1.2rem 1.25rem;
  margin-left: auto;
  margin-top: -54px;
  transform: rotate(270deg);
}

.fake-select.active {
  padding: 1.7rem 1.1rem 1.2rem 1.1rem;
  margin-top: -56px;
  transform: rotate(0);
}

.form-floating {
  display: flex;
  flex-direction: column;
}

.small-text {
  color: #7f8588;
  font-size: 12px;

  u {
    text-decoration: none;
  }
}

.form-control {
  padding-right: 2.5rem;
}

.pill {
  padding: 0.4rem;
  border-radius: 4px;
  width: max-content;
  cursor: pointer;
}

.pillActive {
  padding: 0.4rem 0;
  width: auto;
  background-color: #e9edef !important;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.inputActive {
  &:focus {
    background: rgba(226, 230, 232, 0.4);
    box-shadow: none;
  }
}

.input-select {
  border-radius: 4px 4px 0 0;
}

.form-floating {
  label {
    font-family: $font-secondary !important;
  }
}
</style>
