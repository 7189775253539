import axios from 'axios';
import Swal from 'sweetalert2';

export const accessToken = JSON.parse(sessionStorage.getItem('logged'));

export const xModule = {
  /** company */
  employees: 'mod_empresa_funcionarios',
  'edit-employee': 'mod_empresa_funcionarios',
  'new-employees': 'mod_empresa_funcionarios',
  'new-profile': 'mod_empresa_funcionarios',
  'reversal-reason': 'mod_empresa_funcionarios',
  'discounts-surcharges': 'mod_empresa_descontos_acrescimos',
  'payment-methods': 'mod_empresa_formas_pagamento',
  'entry-category': 'mod_empresa_categ_entrada',

  'product-involved': 'mod_produtos_produtos',

  /** config */
  equipment: 'mod_config_equipamento',
  'advanced-configurations': 'mod_config_avancadas',
  'tax-rules': 'mod_config_regras_fiscais',
  'category-icms': 'mod_config_regras_fiscais',
  'category-pis-cofins': 'mod_config_regras_fiscais',

  /** products */
  service: 'mod_produtos_produtos',
  products: 'mod_produtos_produtos',
  'import-products-result': 'mod_produtos_produtos',
  'new-product': 'mod_produtos_produtos',
  'edit-product': 'mod_produtos_produtos',
  'import-products': 'mod_produtos_produtos',
  'send-file': 'mod_produtos_produtos',
  'import-products-item': 'mod_produtos_produtos',
  'direct-print': 'mod_produtos_produtos',
  'edit-direct-print': 'mod_produtos_produtos',
  'new-direct-print': 'mod_produtos_produtos',
  'product-group': 'mod_produtos_grupo',
  'new-product-group': 'mod_produtos_grupo',
  'fraction-table': 'mod_produtos_fact_tabela',
  'product-observation': 'mod_produtos_observacao',
  'new-product-observation': 'mod_produtos_observacao',
  'manage-observation-price': 'mod_produtos_produtos',
  'edit-product-observation': 'mod_produtos_observacao',

  /** sales */
  sales: 'mod_pdv_vendas',
  catalog: 'mod_pdv_catalogo',
  subcatalog: 'mod_pdv_catalogo',

  /** crme */
  'blocked-clients': 'mod_crm_clientes_cadastrados',
  'registered-customers': 'mod_crm_clientes_cadastrados',
  'exchange': 'mod_crm_permutas',

  'price-table': 'mod_produtos_tabela_preco',
  'manage-price-table': 'mod_produtos_tabela_preco',
  'price-table-publish': 'mod_publicar_itens',

  // relatorios e dashboar
  faturamento: 'mod_dashboards_faturamento',
  estornos: 'mod_dashboards_estornos',
  descontos: 'mod_dashboards_descontos',
  operacional: 'mod_dashboards_operacional',
  'venda-produto': 'mod_dashboards_venda_produto',
  'venda-servico': 'mod_dashboards_venda_servicos',
  'faturamento-canal': 'mod_dashboards_venda_produto',
  'fechamento-caixa': 'mod_dashboards_fechamento_caixa',
  'vendas-funcionario': 'mod_dashboards_vendas_funcionario',
  'vendas-delivery': 'mod_dashboards_vendas_delivery'
};

const mainAxios = axios.create({
  baseURL: process.env.VUE_APP_PRODUCT_API,
  timeout: 1000 * 30,
  headers: {
    'x-modulo':
      xModule[String(location.pathname).substring(1).replace(/\/.*/, '')],
    'x-grupo': accessToken ? accessToken['x-grupo'] : '',
    Authorization: accessToken ? `Bearer ${accessToken['access_token']}` : ''
  }
});

mainAxios.interceptors.request.use((request) => {
  let loggedData = JSON.parse(sessionStorage.getItem('logged'));

  request.headers['x-modulo'] = xModule[String(location.pathname).substring(1).replace(/\/.*/, '')];
  request.headers['x-grupo'] = loggedData['x-grupo'];
  request.headers['Authorization'] = `Bearer ${loggedData['access_token']}`;
  return request;
})

mainAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    console.log(error);

    if (error.response.status === 403) {
      Toast.fire({
        title: 'Não autorizado',
        html: `Usuário não tem permissão!`,
        icon: 'error',
        width: 600,
        showCloseButton: true,
        timerProgressBar: false
      });
      return;
    }

    if (error.response.status === 429) {
      Swal.fire({
        title: 'Atenção',
        text: 'Limite de requisições atingido, aguarde até 1 minuto e volte nessa tela para concluir o que estava fazendo.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (error.response.status === 422 || error.response.status === 400) {
      Toast.fire({
        title: 'Atenção',
        html: error.response.data.data.message,
        icon: 'error',
        width: 600,
        showCloseButton: true,
        timerProgressBar: false
      });
      return;
    }

    if (error.response.status === 500) {
      Toast.fire({
        title: 'Atenção',
        html: error.response.data.message || error.response.data.data.message,
        icon: 'error',
        width: 600,
        showCloseButton: true,
        timerProgressBar: false
      });
      return;
    }

    return error;
  }
);

const dashAxios = axios.create({
  baseURL: 'https://southamerica-east1-painel-novo-169818.cloudfunctions.net',
  timeout: 1000 * 30,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
});

const authAxios = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  timeout: 1000 * 30
});

const userAxios = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  timeout: 1000 * 30,
  headers: {
    Authorization: accessToken ? `Bearer ${accessToken['access_token']}` : ''
  }
});

const lakeAxios = axios.create({
  baseURL: process.env.VUE_APP_LAKE_API,
  timeout: 1000 * 30
});
lakeAxios.interceptors.request.use((req) => {
  let loggedData = JSON.parse(sessionStorage.getItem('logged'));

  req.headers['x-grupo'] = loggedData['x-grupo'];
  req.headers['Authorization'] = `Bearer ${loggedData['access_token']}`;
  return req;
})

const chatAxios = axios.create({
  baseURL: process.env.VUE_APP_CHAT || 'https://chat.epoc.com.br',
  timeout: 10000 * 60,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
});

const chatTokenAxios = axios.create({
  baseURL: 'https://southamerica-east1-painel-novo-169818.cloudfunctions.net',
  timeout: 1000 * 30,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
});

export { mainAxios, authAxios, userAxios, dashAxios, lakeAxios, chatAxios, chatTokenAxios};
