import companyService from '@/services/company.service';
import tablePriceService from '@/services/tablePrice.service';
import publishService from '@/services/publish.service';

export const publish = {
  namespaced: true,
  state: {
    list: {
      meta: {},
      links: {},
      results: []
    },
    status: {},
    modules: {},
  },
  mutations: {
    fill_publish(state, list) {
      state.list = list;
    },
    fill_publish_status(state, status) {
      Object.assign(state.status, status);
    },
    fill_publish_modules(state, modules) {
      Object.assign(state.modules, modules);
    },
    fill_clear_publish_modules(state) {
      state.modules = {};
    },
    fill_clear_publish_status(state) {
      state.status = {};
    }
  },
  actions: {
    async list({ commit }, queryString) {
      commit('fill_clear_publish_status');
      commit('fill_clear_publish_modules');

      const response = await companyService.companies(queryString);
      commit('fill_publish', response.data.data);
    },
    async status({ commit }, { cnpj, codEmp }) {
      const response = await tablePriceService.pubishStatusView(cnpj, codEmp);
      response.code === 200 ? commit('fill_publish_status', response.data.results) : null ;
    },
    async modules({ commit }, { codEmp, updatedAt }) {
      const response = await publishService.count(codEmp, updatedAt)
      response.data.code === 200 ? commit('fill_publish_modules', response.data.data.results) : null ;
    },
  },
  getters: {
    list: (state) => state.list,
    modules: (state) => state.modules,
    status: (state) => state.status,
  },
};
