import accessLogService from '@/services/access.log.service';

export const logaccessModule = {
  namespaced: true,
  state: {
    log: [],
    id: null,
    block: false,
  },
  mutations: {
    fill_log_access(state, log) {
      state.log = log;
    },
  },
  actions: {
    async list({ commit }, { method, module, id, primaryKey, column, filter }) {
      this.state.logaccessModule.block = true;
      let response = await accessLogService
        .latest(
          method,
          module,
          id,
          primaryKey,
          column,
          filter
        );
      commit('fill_log_access', response.data.data.results);
    },
  },
  getters: {
    log: (state) => state.log
  }
};
