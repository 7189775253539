import { mainAxios } from '@/plugins/axios';

class publishService {
  async count(codEmp, updatedAt = '') {
    return await mainAxios.get(
      `/publish-count/${codEmp}?updatedAt=${updatedAt}`
    );
  }
}

export default new publishService();
