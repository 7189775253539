import { mainAxios } from '@/plugins/axios';

class ProfileService {
  async getModules(type) {
    let order = 'order_field=title_modcadm&order=asc';
    if (type === 'GERENCIAL') {
      order = 'order_field=ordem_modcadm&order=asc'
    }

    if (type === 'OPERACIONAL') {
      order = 'order_field=title_modcadm&order=asc'
    }

    const response = await mainAxios.get(
      `/modulos?tipo=${type}&paginate=false&${order}`
    );
    return response.data;
  }
  async view(id) {
    const response = await mainAxios.get(`/perfil/${id}`);
    return response.data;
  }
  async create(payload) {
    return await mainAxios.post(`/perfil`, payload);
  }
  async update(id, payload) {
    return await mainAxios.put(`/perfil/${id}`, payload);
  }
  async list() {
    const response = await mainAxios.get(`/perfil?paginate=false`);
    return response.data;
  }
}

export default new ProfileService();
