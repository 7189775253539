<template>
  <h2 :class="H2Props.class" :id="H2Props.id" >
    <button
      :class="buttonProps.class"
      :type="buttonProps.type"
      :data-bs-toggle="buttonProps.dataBsToggle"
      :data-bs-target="buttonProps.dataBsTarget"
      :aria-expanded="true"
      :aria-controls="buttonProps.ariaControls"
    >
      <div class="w-100">
        <span class="span-header">{{ accordionDesc }}</span>
        <span class="span-title">{{ accordionName }}</span>
      </div>
      <div class="status w-100 mt-4">
        <div v-if="!skippedStatus">
          <p v-if="!status" class="pendente">
            <span class="label-status">
              <em class="bi bi-exclamation-lg"></em
              ></span>
            &nbsp;{{ statusTitle || 'Pendente' }}
          </p>
          <p v-else class="pendente">
          <span class="label-status">
            <em class="bi bi-check icon-status"></em>
          </span>
            &nbsp;Configurado
          </p>
        </div>
        <p class="user">
          Usuário:
          <span class="label-user"
          ><em class="bi bi-person-fill"></em> {{ log.user || 'Nenhum' }}</span
          >
        </p>
        <p v-if="skippedStatus" class="pendente">
          Criado em: <span class="label-status mx-2 p-2">{{ log.createdAt || new Date() | date
          }}</span>
        </p>
        <p class="last-edition">
          Última edição
          <span class="label-edition">{{ log.updatedAt || '0d' }}</span>
        </p>
      </div>
      <slot />
    </button>
  </h2>
</template>
<script>
import accessLogService from '@/services/access.log.service';
import { mapState } from 'vuex';

export default {
  name: 'EpAccordionHeaderStatus',
  props: {
    forceupdated: { required: false, type: Boolean },
    store: { required: false, type: Boolean },
    statusTitle: { required: false, type: String },
    skippedStatus: { required: false, type: Boolean },
    createdAt: { required: false, type: String },
    status: { required: false, type: Boolean },
    id: { required: false },
    primaryKey: { required: false },
    column: { required: false },
    filter: { required: false },
    method: { required: false, type: String },
    module: { required: false, type: String },
    accordionName: { required: false, type: String },
    accordionDesc: { required: false, type: String },
    buttonProps: { required: true, type: Object },
    H2Props: { required: true, type: Object }
  },
  filters: {
    date(value) {
      if (!value) {
        return '';
      }
      return new Intl.DateTimeFormat('pt-BR').format(new Date(value));
    }
  },
  data() {
    return {
      log: {
        user: null,
        updatedAt: null,
        createdAt: null
      }
    };
  },
  watch: {
    'id'(value) {
      this.logging();
    },
    'createdAt'(value) {
      if (!this.store) {
        this.log.createdAt = value;
      }
    },
    '$store.state.logaccessModule.log'(value) {
      if (this.store) {
        this.log.user = value.usuario ? value.usuario.nome_usuario : null;
        this.log.updatedAt = value.updated_at;
      }
    },
  },
  computed: {
    ...mapState(['logaccessModule'])
  },
  mounted() {
    this.logging();
  },
  methods: {
    logging() {
      if (this.id || this.filter) {
        if (
          this.store
        ) {
          if (
            Number(this.id) !== Number(this.$store.state.logaccessModule.id)
          ) {
            this.$store.dispatch('logaccessModule/list',
              {
                method: this.method,
                module: this.module,
                id: this.id,
                primaryKey: this.primaryKey,
                column: this.column,
                filter: this.filter
              }
            )
              .then(() => {
                if (this.$store.state.logaccessModule.log.usuario) {
                  this.log.user = this.$store.state.logaccessModule.log.usuario.nome_usuario;
                  this.log.updatedAt = this.$store.state.logaccessModule.log.updated_at;
                }
              });
          }
          return;
        }

        accessLogService
          .latest(
            this.method,
            this.module,
            this.id,
            this.primaryKey,
            this.column,
            this.filter
          )
          .then(response => {
            let result = response.data.data.results;
            if (result) {
              this.log.user = result.usuario.nome_usuario;
              this.log.updatedAt = result.updated_at;
            }
          });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pendente {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    em {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .label-status {
    margin: auto;
    display: inline-flex;
    background: #e9edef;
    border-radius: 16px;

    em {
      width: 24px;
      height: 24px;
      font-size: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }

  .user {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .label-user {
    margin-left: 10px;
    background: #e9edef;
    border-radius: 16px;
    padding: 2px 8px 2px 2px;
    height: 30px;
    display: inline-flex;
    align-items: center;

    em {
      width: 24px;
      height: 24px;
      background: #373c3f;
      color: #fff;
      font-size: 28px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-right: 6px;
    }
  }

  .last-edition {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;

    .label-edition {
      min-width: 30px;
      padding: 2px 2px 2px 2px;
      height: 30px;
      background: #e9edef;
      border-radius: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.accordion-button {
  font-size: 20px;
  font-weight: 700;
  color: #2d3032;
  border-left: 8px solid #c0c5c8;
  background-color: white;
  border-top: 1px solid #e9edef;
  border-right: 1px solid #e9edef;
  border-bottom: 1px solid #e9edef;
  box-shadow: none;
  display: inline-block;
  position: relative;

  &:disabled {
    color: #C0C5C8;
    border-left: 8px solid #e6e8e9 !important;
  }

  .span-title {
    /* Loren InpLoren InpLoren InpLoren InpLoren */

    width: 368px;
    height: 26px;
    /* XS-16px-SemiBold */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */
    display: flex;
    align-items: center;
    text-align: justify;

    /* Neutral Color/08 */
    color: #373C3F;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

  }

  .span-header {
    /* XXXS-12px-Regular */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
    /* or 19px */
    display: flex;
    align-items: center;
    text-align: justify;

    /* Neutral Color/05 */
    color: #7F8588;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

  }

  &::after {
    content: '\F285';
    font-family: 'bootstrap-icons';
    background-image: unset;
    position: absolute;
    right: 24px;
    top: 24px;
  }

  &:not(.collapsed) {
    &::after {
      content: '\F282';
      font-family: 'bootstrap-icons';
      transform: unset;
    }
  }

  &:not(.collapsed)::after {
    content: '\F282';
    font-family: 'bootstrap-icons';
    transform: unset;
  }

  &:focus {
    box-shadow: unset;
  }

  span {
    font-weight: 400;
    font-size: 12px;
  }
}

.icon-status {
  background: #872240;
  color: #fff;
}
</style>
