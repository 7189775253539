import TablePriceService from '@/services/tablePrice.service';

export const tablePrice = {
  namespaced: true,
  state: {
    companies: [],
    tables: {
      results: []
    },
    page: 1,
    queryString: undefined,
    url: undefined,
    newTable: '',
    products: {},
    modules: {}
  },
  mutations: {
    FILL_COMPANY(state, companies) {
      state.companies = companies;
    },
    FILL_PRICE_TABLE(state, tables) {
      state.tables = tables;
    },
    FILL_PRODUCTS(state, products) {
      let page = new URLSearchParams(this.state.queryString).get('page');
      if (Number(page) > 1) {
        this.state.tablePrice.page = Number(page);
        if (Number(products.length) > 0) {
          products.map((obj) => {
            state.products.push(obj);
          });
        }
      } else {
        state.products = products;
      }
    },
    FILL_NEW_TABLE(state, newTable) {
      state.newTable = newTable;
    },
    FILL_MODULES(state, modules) {
      state.modules = modules;
    },
  },
  actions: {
    async getCompanies({ commit }) {
      const response = await TablePriceService.getCompany();
      response.code === 200 ? commit('FILL_COMPANY', response.data) : null;
    },
    async getPriceTableAll({ commit }) {
      const response = await TablePriceService.getPriceTableAll();
      response.code === 200 ? commit('FILL_PRICE_TABLE', response.data) : null;
    },
    async getPriceTable({ commit }) {
      const response = await TablePriceService.getPriceTable();
      response.code === 200 ? commit('FILL_PRICE_TABLE', response.data) : null;
    },
    async getPriceTableStatus({ commit }) {
      const response = await TablePriceService.getPriceTable(true);
      response.code === 200 ? commit('FILL_PRICE_TABLE', response.data) : null;
    },
    async changeTable({ dispatch }, payload) {
      const response = await TablePriceService.changePriceTable(payload.id, {
        cod_tabela: payload.data
      });
      response.code === 200 ? dispatch('getCompanies') : null;
    },
    async getProducts({ commit }) {
      let url = '/gerenciar-empresa?page=1&per_page=20';
      if (this.state.queryString) {
        url = `/gerenciar-empresa?` + this.state.queryString;
      }

      let response = await TablePriceService.getProducts(url);
      response !== undefined ? commit('FILL_PRODUCTS', response.data) : null;
    },
    async searchProduct({ commit }, search) {
      const response = await TablePriceService.searchProduct(search);
      response !== undefined ? commit('FILL_PRODUCTS', response.data) : null;
    },
    async submitPrice({ commit }, payload) {
      return TablePriceService.submitPrice(payload)
        .then()
        .catch((error) => {
          throw error.response.data;
        });
    },
    async submitTablePrice({ commit }, payload) {
      return TablePriceService.submitTables(payload);
    },
    async createTable({ dispatch }, payload) {
      return TablePriceService.createTable(payload)
        .then(() => {
          Toast.fire('Tabela(s) criada(s) com sucesso!', '', 'success');
          dispatch('getPriceTable');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `Ocorreu;
      um;
      erro;
      ao;
      criar;
      tabela(s);
      !Por;
      favor, tente;
      novamente.`,
            icon: 'error'
          });
          throw error.response.data;
        });
    },
    async removeTable({ commit }, id) {
      return TablePriceService.deleteTable(id)
        .then(() => {
          Toast.fire('Tabela excluída com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `;
      Ocorreu;
      um;
      erro;
      ao;
      excluir;
      tabela;
      !Por;
      favor, tente;
      novamente.`,
            icon: 'error'
          });
          throw error.response.data;
        });
    },
    async getModules({ commit }, search = '') {
      const response = await TablePriceService.getModules(search);
      response.code === 200 ? commit('FILL_MODULES', response.data) : null;
    }
  },
  getters: {
    companies: (state) => state.companies,
    tables: (state) => state.tables,
    products: (state) => state.products,
    page: (state) => state.page,
    perPage: (state) => state.perPage
  }
};
