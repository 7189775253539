<template>
  <div
    class="container-loading"
    v-if="loading"
  >
    <div class="spinner-border" role="status">
      <p class="visually-hidden">Loading...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpLoading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="css" scoped>
.container-loading {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden!important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.spinner-border {
  width: 5rem;
  height: 5rem;
  color: #3d0f1c;
}
</style>
