

<template>
  <div
    :key="randomKey"
    class="col-auto col-sm-auto col-md-auto col-lg-auto d-flex m-auto text-end"
  >
    <div
      style="font-size: 0.8rem"
      class="d-flex text-secondary text-end ms-1 m-auto"
      :key="i"
      v-for="(status, i) in legend"
    >
      <span :class="[status.symbol, 'rounded mt-1 mx-1']"></span>
      {{ status.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'EpLegend',
  props: {
    force: { type: Boolean, required: false, default: null },
    legend: {
      Array,
      length: 0,
      Object: {
        title: String,
        symbol: String,
        native: Boolean
      }
    },
  },
  data() {
    return {
      randomKey: Math.random(),
    }
  },
  watch: {
    force() {
      this.randomKey = Math.random();
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ellipse {
    /* Ellipse 134 */
    width: 10px;
    height: 10px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .ellipse-danger {
    /* Feedback/Warning/Warning Pure */
    background: #fa2349;
  }

  .ellipse-success {
    /* Feedback/Warning/Warning Pure */
    background: #139A5A;
  }

  .ellipse-info {
    /* Feedback/Warning/Warning Pure */
    background: #4c8dff;
  }

  .ellipse-light {
    /* Feedback/Warning/Warning Pure */
    background: #dcdde4;
  }

  .ellipse-light-gray {
    /* Feedback/Warning/Warning Pure */
    background: #adb5bd;
  }

  .ellipse-warning {
    /* Feedback/Warning/Warning Pure */
    background: #fc8332;
  }
}
</style>
