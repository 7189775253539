<template>

  <div>
    <div class="">
      <div class="d-flex mb-2 mt-2 col-12 flex-wrap" v-if="!!model.length">
        <span class="badge bg-primary rounded-2" v-for="(m, i) in model" :key="i">
          {{ m[label] }}
          <a
            :style="{ cursor: 'pointer' }"
            @click="remove(i, m[label])"
          >&nbsp; X</a>
        </span>
      </div>
    </div>
    <div>
      <div class="input form-floating">
        <input
          v-bind="$attrs"
          autocomplete="off"
          ref="input"
          :type="type"
          :placeholder="placeholderName"
          :aria-label="ariaLabelName"
          :id="idName"
          @input="search = $event.target.value"
          @click="
          () => {
            showoptions = !showoptions;
          }
        "
          :class="{
            inputActive: arrowCounter === -1,
            'form-control input-select': true,
            'invalid is-invalid': hasError,
        }"
          style="background-image: none"
          @keydown="debounceSearch"
        />
        <img
          class="fake-select"
          :class="showoptions ? 'active' : ''"
          src="../../assets/img/Vector.svg"
          alt=""
          @click="
          () => {
            showoptions = !showoptions;
          }
        "
        />
        <label :for="idName">{{ labelName }}</label>
        <small v-if="hasError" class="text-danger mt-2"
               style="font-size:12px; font-family: 'Montserrat', sans-serif;">
          {{ error }}
        </small>
      </div>
      <div v-if="showoptions" class="options">
        <p class="small-text" v-if="!labelDescription">
          <u>Selecione</u> um(a) {{ fieldName }}
        </p>
        <p class="small-text" v-else>
          {{ labelDescription }}
        </p>
        <ul class="list">
          <li
            v-for="(option, index) in options"
            :ref="`option_${index}`"
            :key="option[id]"
            @click="selectedOption(option)"
            :class="{ pillActive: arrowCounter === index }"
          >
          <span
            class="pill"
            v-if="option.cor !== undefined"
            :style="{
              background: option['cor'] || 'rgb(233, 237, 239)'
            }"
          >
            {{ option[label] }}
          </span>
            <span class="pill" v-else>{{ option[label] }}</span>
          </li>
          <li v-if="!options.length && this.model[this.label]">
            <span slot="noResult">Nenhum resultado encontrado.</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import returnGenericColor from '@/mixins/returnGenericColor';
import { debounce } from '@/plugins/debounce';

export default {
  name: 'EpInputMultipleSelectOrCreate',
  mixins: [returnGenericColor],
  props: {
    options: { type: Array, required: true, default: () => [] },
    id: { type: String || Number, required: true },
    label: { type: String || Number, required: true },
    labelName: { type: String, required: true },
    fieldName: { type: String, required: true },
    idName: { type: String, required: true },
    ariaLabelName: { type: String, required: true },
    placeholderName: { type: String, required: true },
    type: { type: String, required: true },
    nameEmit: { type: String, required: true },
    labelDescription: { type: String, required: false },
    call: {
      search: (value) => {},
      open: () => {}
    },
    selectadOptions: [],
    aditionalFilds: [],
    hasError: Boolean,
    error: String
  },
  data() {
    return {
      search: null,
      debounceSearch: () => {
      },
      showoptions: false,
      firstShow: 0,
      arrowCounter: -1,
      isOptionValid: false
    };
  },
  watch: {
    showoptions: function() {
      if (!this.options.length) {
        this.call.open();
      }
    },
    model: function(value) {
      this.$emit('update:' + this.nameEmit, value);
    },
  },
  computed: {
    model: {
      get() {
        return this.selectadOptions;
      }
    }
  },
  mounted() {
    this.debounceSearch = debounce(() => this.onKeydown(), 600);
  },
  methods: {
    onKeydown() {
      if (!this.showoptions) {
        this.showoptions = true;
      }
      this.call.search(this.search);
    },
    selectedOption(option) {
      this.model.push(option);
      this.$emit('update:' + this.nameEmit, this.model);
    },
    remove(index, value) {
      this.model.splice(index, 1);
      this.$emit('update:removeTags', value);
      this.$emit('update:' + this.nameEmit, this.model);
    }
  }
};
</script>
<style lang="scss" scoped>
.badge.bg-primary {
  background-color: #872240 !important;
}

.options {
  position: relative;
  background: #fff;
  z-index: 9999999;
  width: 100%;
  border-radius: 0 0 4px 4px;
  border: 1px solid #dcdde4;
  padding: 1rem 0.5rem;

  .create-option {
    width: 100%;
    padding: 4px;
    position: relative;
    top: 0;
    border: 1px solid #dcdde4;
    background: #fafafa;
    border-radius: 4px;
    color: #373c3f;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;

    strong {
      margin-right: 5px;
      color: #7f8588;
    }
  }
}

.fake-select {
  cursor: pointer;
  padding: 1.5rem 1.25rem 1.2rem 1.25rem;
  margin-left: auto;
  margin-top: -54px;
  transform: rotate(270deg);
}

.fake-select.active {
  padding: 1.7rem 1.1rem 1.2rem 1.1rem;
  margin-top: -56px;
  transform: rotate(0);
}

.form-floating {
  display: flex;
  flex-direction: column;
}

.small-text {
  color: #7f8588;
  font-size: 12px;

  u {
    text-decoration: none;
  }
}

.form-control {
  padding-right: 2.5rem;
}

.pill {
  padding: 0.4rem;
  border-radius: 4px;
  width: max-content;
  cursor: pointer;
}

.pillActive {
  padding: 0.4rem 0;
  width: auto;
  background-color: #e9edef !important;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.inputActive {
  &:focus {
    background: rgba(226, 230, 232, 0.4);
    box-shadow: none;
  }
}

.input-select {
  border-radius: 4px 4px 0 0;
}

.form-floating {
  label {
    font-family: $font-secondary !important;
  }
}
</style>
