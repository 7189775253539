var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{},[(!!_vm.model.length)?_c('div',{staticClass:"d-flex mb-2 mt-2 col-12 flex-wrap"},_vm._l((_vm.model),function(m,i){return _c('span',{key:i,staticClass:"badge bg-primary rounded-2"},[_vm._v(" "+_vm._s(m[_vm.label])+" "),_c('a',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.remove(i, m[_vm.label])}}},[_vm._v("  X")])])}),0):_vm._e()]),_c('div',[_c('div',{staticClass:"input form-floating"},[_c('input',_vm._b({ref:"input",class:{
          inputActive: _vm.arrowCounter === -1,
          'form-control input-select': true,
          'invalid is-invalid': _vm.hasError,
      },staticStyle:{"background-image":"none"},attrs:{"autocomplete":"off","type":_vm.type,"placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName},on:{"input":function($event){_vm.search = $event.target.value},"click":() => {
          _vm.showoptions = !_vm.showoptions;
        },"keydown":_vm.debounceSearch}},'input',_vm.$attrs,false)),_c('img',{staticClass:"fake-select",class:_vm.showoptions ? 'active' : '',attrs:{"src":require("../../assets/img/Vector.svg"),"alt":""},on:{"click":() => {
          _vm.showoptions = !_vm.showoptions;
        }}}),_c('label',{attrs:{"for":_vm.idName}},[_vm._v(_vm._s(_vm.labelName))]),(_vm.hasError)?_c('small',{staticClass:"text-danger mt-2",staticStyle:{"font-size":"12px","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),(_vm.showoptions)?_c('div',{staticClass:"options"},[(!_vm.labelDescription)?_c('p',{staticClass:"small-text"},[_c('u',[_vm._v("Selecione")]),_vm._v(" um(a) "+_vm._s(_vm.fieldName)+" ")]):_c('p',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm.labelDescription)+" ")]),_c('ul',{staticClass:"list"},[_vm._l((_vm.options),function(option,index){return _c('li',{key:option[_vm.id],ref:`option_${index}`,refInFor:true,class:{ pillActive: _vm.arrowCounter === index },on:{"click":function($event){return _vm.selectedOption(option)}}},[(option.cor !== undefined)?_c('span',{staticClass:"pill",style:({
            background: option['cor'] || 'rgb(233, 237, 239)'
          })},[_vm._v(" "+_vm._s(option[_vm.label])+" ")]):_c('span',{staticClass:"pill"},[_vm._v(_vm._s(option[_vm.label]))])])}),(!_vm.options.length && this.model[this.label])?_c('li',[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Nenhum resultado encontrado.")])]):_vm._e()],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }